<template>
  <b-card v-if="user" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Resumo Atendimento Profissional</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0" />
    </b-card-header>
    <TableComponents
      v-if="!showForm"
      :columns="columns"
      :rows="listaAtendimentos"
      sort-name="nome"
      title-table="Atendimentos"
      module="atendimentoProfissionalModule"
      :show-btn-add="false"
      :show-action-delete="false"
      @edit="edit"
    />
  </b-card>
</template>

<script>
import TableComponents from '@/layouts/components/TableComponents.vue'
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import { BCard, BCardHeader, BCardTitle, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    TableComponents
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoProfissionalModule', {
      listaAtendimentos: (state) => state.listaAtendimentos
    })
  },
  data() {
    return {
      user: {},
      showForm: false,
      columns: [
        {
          label: 'Nº Atendimento',
          field: 'numeroAtendimento'
        },
        {
          label: 'Data Atendimento',
          field: 'dataAtendimento',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: true
        },
        {
          label: 'Nome Solicitante',
          field: 'registro.nome',
          sortable: true
        },
        {
          label: 'Situação',
          field: 'situacao.descricao',
          sortable: false
        },

        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    async edit(data) {
      this.$router.push({
        path: `/modules/attendance/attendance/editAtendimentoProfissional/${data.id}`
      })
    },
    async cancelar() {
      this.showForm = false
    },
    loadTable() {
      this.$store.dispatch('atendimentoModule/loadTable', this.pageConfig)
    }
  }
}
</script>
